import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoaderService } from '../loader';

@Injectable()
export class GlobalHttpInterceptorService {

  constructor(private router: Router,
              private loader: LoaderService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
          switch (error.status) {
            case 404:
            case 500:
              this.router.navigate(['./error/500']);
              this.loader.hide();
          }
          return throwError(error);
      })
    );
  }
}
