import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import { environment } from 'src/environments/environment';
import { ISgwtConnectElement } from 'src/main';
import { PiwikTrackingService } from './core/analytics/piwik-tracking.service';



@Component({
  selector: 'liabilities-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('footer', {static: true}) footer  :ElementRef;

  version = environment.version;
  private subscriptionHandle = null;

  constructor(private piwikTrackingService: PiwikTrackingService) {
    this.piwikTrackingService.configurePiwikTracker();
    this.piwikTrackingService.enableRouteNavigationTracking();
  }

  ngOnInit() {
    this.getVersionTag();
    const bus = (<any>window)['SGWTWidgetConfiguration']?.bus

    if (bus) {
      this.subscriptionHandle = bus.subscribe('global.language', (language: string | undefined) => {
        let accessibilityLink = '/assets/SOCIETE-GENERALE-SG Markets Fund Alerts-Declaration-Accessibility-2021-03.pdf';
        if (language?.toLowerCase() === 'fr') {
          accessibilityLink = '/assets/SOCIETE-GENERALE-SG Markets Fund Alerts-Declaration-Accessibilite-2021-03.pdf';
        }
        if (this.footer?.nativeElement) {
          this.footer.nativeElement.accessibilityLink = accessibilityLink;
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.subscriptionHandle) {
      (<any>window)['SGWTWidgetConfiguration']?.bus?.unsubscribe(this.subscriptionHandle);
    }
  }

  getVersionTag() {
    const version = document.createComment(this.version);
    document.body.appendChild(version);
  }
}
