export interface IFundModel {
  subFundId: string;
  subFundName: string;
  isin: string;
  fundName: string;
  fundCurrency: string;
  subFundCurrency: string;
  umbrellaCurrency: string
  umbrellaId: string
  umbrellaName: string
  role: string;
  roleCTAOrigin: FundRoleOrigin;
  roleLTAOrigin: FundRoleOrigin;
  notificationPurgeFrequency: string;
  managementCompanyCode: string;
  managementCompanyRCTCode: string;
  authorized: boolean;
  cutOffTime: string;
  fundType: string
  fundValuationFrequencies: string
}

export interface IFunds {
  value: IFundModel[];
}

export enum Role {
  LTA = 'LTA',
  CTA = 'CTA'
}
export enum FundRoleOrigin {
  SORA = 'SORA',
  MFTA = 'MFTA'
}


export enum FundSearchMode {
  None = 0,
  Amount = 1,
  Percentage = 2,
  AmountPercentage = Amount | Percentage
}
export interface ICurrencies {
  value: string[];
}
