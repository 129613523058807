import { Injectable } from '@angular/core';
import { formatNumber } from '@angular/common';
import { DIGITS_INFO, LOCAL } from '../../pipes/common';

@Injectable({
    providedIn: 'root'
})
export class AmountFormatService {

    //TODO : Clean up with Amount Pipe

    transform(value: number | null | undefined, symbol: string = '', digitsInfo: string = DIGITS_INFO, locale: string = LOCAL) {
        if (!value) {
            return '';
        }
        return formatNumber(value, locale, digitsInfo) + symbol;
    }

    parse(value: string): number {
        const result = +((value || '').split(',').join(''));
        if (isNaN(result)) {
            return 0;
        } else {
            return result;
        }
        return +this.parse(value);
    }
}
