import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from 'src/app/core';
import { IAlertModel, IAlertsModel, IAlertUpdateRequest, IAlertUpdateStatusRequest, IAlertCreationRequest } from '../../models';
import { Observable } from 'rxjs';

@Injectable()
export class AlertService {
  constructor(private httpClient: HttpClient, private configurationService: ConfigurationService) {
  }

  public getAlert(alertId: number): Observable<IAlertModel> {
    return this.httpClient.get<IAlertModel>(this.configurationService.getAlertUrl(alertId));
  }

  public getAllAlerts(): Observable<IAlertsModel> {
    return this.httpClient.get<IAlertsModel>(this.configurationService.getAlertsUrl());
  }

  public createAlert(alert: IAlertCreationRequest) {
    return this.httpClient.post(this.configurationService.createAlertUrl(), alert);
  }

  public updateAlert(alertId: number, alertUpdateReq: IAlertUpdateRequest) {
    return this.httpClient.put(this.configurationService.updateAlertUrl(alertId), alertUpdateReq);
  }

  public updateAlertStatus(alertId: number, alertUpdatStatusReq: IAlertUpdateStatusRequest) {
    return this.httpClient.put(this.configurationService.updateAlertStatusUrl(alertId), alertUpdatStatusReq);
  }

  public subscribeAlert(alertId: number) {
    return this.httpClient.put(this.configurationService.subscribeAlertUrl(+alertId), null);
  }

  public unsubscribeAlert(alertId: number) {
    return this.httpClient.put(this.configurationService.unsubscribeAlertUrl(+alertId), null);
  }
}
