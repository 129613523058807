
export interface IcUserApiModel {
  readonly icId: string;
  readonly firstName: string;
  readonly lastName: string;
}

export interface IcUserModel {
  readonly icId: string;
  readonly firstName: string;
  readonly lastName: string;
}

export const toIcUserModel = (model: IcUserApiModel): IcUserModel => {
    return {
        ...model
    };
};
