import { Injectable } from '@angular/core';
import { ConfigurationService } from 'src/app/core';
import { HttpClient } from '@angular/common/http';
import { map, tap, catchError } from 'rxjs/operators';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { IcUserModel } from 'src/app/shared/models';

@Injectable()
export class UserService {
  private userInfo$ = new BehaviorSubject<IcUserModel | null>(null);

  constructor(private configurationService: ConfigurationService, private http: HttpClient) { }

  public checkServiceAccess(): Observable<boolean> {
    return this.http.get<IcUserModel>(this.configurationService.getCheckServiceAccessUrl())
      .pipe(
        tap(user => this.userInfo$.next(user)),
        map(() => true),
        catchError(() => of(false))
    );
  }

  public getUser(): Observable<IcUserModel | null> {
    return this.userInfo$.asObservable();
  }
}
