import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable()
export class NavigationService {

  private previousUrl: string;
  private currentUrl: string;

  constructor(private router: Router) {
    router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = (event as NavigationEnd).url;
    });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  public goToAlerts() {
    this.router.navigate(['/alerts']);
  }

  public goToNotifications() {
    this.router.navigate(['/notifications']);
  }
}
