import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthorizationsGuard } from './authorization/guards/authorizations.guard';


export const appRoutes: Routes = [
  {
    path: 'alerts',
    canActivate: [AuthorizationsGuard],
    loadChildren: () => import('./alert/alert.module').then(m => m.AlertModule)
  },
  {
    path: 'notifications',
    canActivate: [AuthorizationsGuard],
    loadChildren: () => import('./notification/notification.module').then(m => m.NotificationModule)
  },
  {
    path: 'unauthorized',
    loadChildren: () => import('./authorization/authorization.module').then(m => m.AuthorizationModule)
  },
  {
    path: 'error',
    loadChildren: () => import('./error-handling/error-handling.module').then(m => m.ErrorHandlingModule)
  },
  { path: '', redirectTo: 'notifications', pathMatch: 'full'},
  { path: '**', redirectTo: 'notifications', pathMatch: 'full' }
];


@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules, useHash: true, enableTracing: false })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
