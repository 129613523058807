import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ApiConnectionErrorComponent } from './components/api-connection-error/api-connection-error.component';

const routes: Routes = [
  { path: '500', component: ApiConnectionErrorComponent },
  { path: '', redirectTo: 'ApiConnectionErrorComponent', pathMatch: 'full'},
  { path: '**', redirectTo: '/', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})

export class ErrorRoutingModule {
}
