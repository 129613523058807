import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoaderService } from '../loader-service/loader.service';
import { Subject } from 'rxjs';
import { LoaderState } from '../loader.model';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'liabilities-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit, OnDestroy {
  public show = false;
  private shutdown$ = new Subject<void>();

  constructor(private loaderService: LoaderService) { }

  ngOnInit() {
    this.loaderService.loaderState
      .pipe(takeUntil(this.shutdown$))
      .subscribe((state: LoaderState) => {
        this.show = state.show;
      });
  }

  ngOnDestroy() {
    this.shutdown$.next();
    this.shutdown$.complete();
  }
}
