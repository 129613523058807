import { environment } from 'src/environments/environment';

export class ConfigurationService {
  private _serviceBaseUrl = environment.frontApiBaseUrl;

  //#region funds

  public getFundsUrl(): string {
    return `${this._serviceBaseUrl}/funds`;
  }

  public getFundUrl(): string {
    return `${this._serviceBaseUrl}/funds/_search`;
  }

  public getCurrenciesUrl(): string {
    return `${this._serviceBaseUrl}/currencies`;
  }

  //#endregion Funds

  //#region alert-groups

  public getAlertsUrl(): string {
    return `${this._serviceBaseUrl}/alert-groups`;
  }

  public getAlertUrl(alertId: number): string {
    return `${this._serviceBaseUrl}/alert-groups/${alertId}`;
  }

  public createAlertUrl(): string {
    return `${this._serviceBaseUrl}/alert-groups`;
  }

  public updateAlertUrl(alertId: number): string {
    return `${this._serviceBaseUrl}/alert-groups/${alertId}`;
  }

  public updateAlertStatusUrl(alertId: number): string {
    return `${this._serviceBaseUrl}/alert-groups/${alertId}/_update-status`;
  }

  public subscribeAlertUrl(alertId: number): string {
    return `${this._serviceBaseUrl}/alert-groups/${alertId}/_subscribe`;
  }
  public unsubscribeAlertUrl(alertId: number): string {
    return `${this._serviceBaseUrl}/alert-groups/${alertId}/_unsubscribe`;
  }

  //#endregion Alert-groups

  //#region notifications

  public getAllManagementCompanyNotificationsUrl(): string {
    return `${this._serviceBaseUrl}/notifications`;
  }

  public getNotificationUrl(notificationId: number): string {
    return `${this._serviceBaseUrl}/notifications/${notificationId}`;
  }

  //#endregion

  //#region management-company

  public getManagementCompanyIcUsersUrl(): string {
    return `${this._serviceBaseUrl}/management-companies/_me/users`;
  }

  //#endregion


  //#region user

  public getCheckServiceAccessUrl(): string {
    return `${this._serviceBaseUrl}/users/_me/_service-access`;
  }

  //#endregion
}
