import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { IChangeEvent } from 'sgwt-account-center';

@Component({
  selector: 'liabilities-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements AfterViewInit, OnDestroy {
  @ViewChild('sgwtAccountCenter', { static: false })
  sgwtAccountCenter: ElementRef;

  constructor(public translate: TranslateService) {
    translate.setDefaultLang('en');
    translate.use('en');
  }

  ngAfterViewInit() {
    this.sgwtAccountCenter.nativeElement.addEventListener(
      'sgwt-account-center--language-changed',
      this.onLanguageChanged
    );
  }

  ngOnDestroy() {
    this.sgwtAccountCenter.nativeElement.removeEventListener(
      'sgwt-account-center--language-changed',
      this.onLanguageChanged
    );
  }

  onLanguageChanged = (evt: IChangeEvent) => {
    this.translate.use(evt.detail.language.toLowerCase());
  }

}
