import { IAlertFundModel } from './alert-fund.model';
import { IcUserModel } from '.';
import { IFundModel } from './fund.model';

export class AlertModel implements IAlertModel {
  id: number;
  alertLabel: string;
  alertCategory: string;
  currency: string;
  funds: IAlertFundModel[];
  definedThresholdSense: number;
  definedThreshold: number | null;
  definedAmountSense: number;
  definedAmount: number | null;
  notificationMailingList: string[];
  notificationModes: string[];
  notificationIcIds: string[] | null;
  notificationContacts: IcUserModel[];
  ownerFirstName: string;
  ownerLastName: string;
  ownerIcId: string;
  enabled: boolean;
  creationDate: Date;
  modificationDate: Date;
  withWholePerimeter: boolean;


  constructor(alert: IAlertModel) {
    this.id = alert.id;
    this.alertLabel = alert.alertLabel;
    this.alertCategory = alert.alertCategory;
    this.currency = alert.currency;
    this.funds = alert.funds;
    this.definedThresholdSense = alert.definedThresholdSense;
    this.definedThreshold = alert.definedThreshold;
    this.definedAmountSense = alert.definedAmountSense;
    this.definedAmount = alert.definedAmount;
    this.notificationMailingList = alert.notificationMailingList;
    this.notificationModes = alert.notificationModes;
    this.notificationContacts = alert.notificationContacts;
    this.ownerFirstName = alert.ownerFirstName;
    this.ownerLastName = alert.ownerLastName;
    this.ownerIcId = alert.ownerIcId;
    this.enabled = alert.enabled;
    this.creationDate = alert.creationDate;
    this.withWholePerimeter = alert.withWholePerimeter;
    this.modificationDate = alert.modificationDate;
    this.notificationIcIds = alert.notificationIcIds ?? alert.notificationContacts ? alert.notificationContacts?.map(x => x.icId) : null;
  }

}

export enum ThresholdSense {
  Positive = 1,
  Negative = -1
}

export enum AlertCategory {
  Subscription = 'SUBSCRIPTION',
  Redemption = 'REDEMPTION',
  Cutoff = 'CUTOFF',
  Materiality = 'MATERIALITY'
}

export enum NotificationMode {
  Widget = 'WIDGET',
  Email = 'EMAIL',
  MobileApp = 'MOBILEAPP'
}

export type AlertFilterType = 'Active' | 'Disabled' | 'Yourteam';
export type AlertTypeDict<T> = { [k in AlertFilterType]: T };

export interface IAlertModel {
  id: number;
  alertLabel: string;
  alertCategory: string;
  currency: string;
  funds: IAlertFundModel[];
  definedThresholdSense: number;
  definedThreshold: number | null;
  definedAmountSense: number;
  definedAmount: number | null;
  notificationMailingList: string[];
  notificationModes: string[];
  notificationIcIds: string[] | null;
  notificationContacts: IcUserModel[];
  ownerIcId: string;
  ownerFirstName: string;
  ownerLastName: string;
  enabled: boolean;
  creationDate: Date;
  modificationDate: Date;
  withWholePerimeter: boolean;
}

export interface IAlertUpdateRequest {
  id: number;
  alertLabel: string;
  currency: string | null;
  definedThresholdSense: number | null;
  definedThreshold: number;
  definedAmountSense: number | null;
  definedAmount: number;
  funds: IFundModel[];
  notificationModes: string[];
  notificationIcIds: string[];
  withWholePerimeter: boolean;

}

export interface IAlertCreationRequest {

  alertLabel: string;
  alertCategory: string;
  definedThresholdSense: number;
  definedThreshold: number;
  definedAmountSense: number;
  definedAmount: number;
  currency: string;
  funds: IFundModel[];
  notificationModes: string[];
  notificationIcIds: string[];
  withWholePerimeter: boolean;
}

export interface IAlertUpdateStatusRequest {
  alertId: number;
  enabled: boolean;
}
