import { IcUserModel, toIcUserModel, IcUserApiModel } from './ic-user.model';

export interface IcUsersApiModel {
  value: IcUserApiModel[];
}

// tslint:disable-next-line:array-type
export type IcUsersModel = Array<IcUserModel>;

export const toIcUsersModel = (model: IcUsersApiModel): IcUsersModel => {
    return (model && model.value || []).map(c => toIcUserModel(c));
};
