import { Component } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';


@Component({
  selector: 'liabilities-toast',
  styles: [`
  :host {
    pointer-events: auto;}
  `],
  template: `
  <div role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
      <strong>{{ title }}</strong>
      <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" (click)="remove()" aria-label="Close">
        <i class="icon">close</i>
      </button>
    </div>
    <div class="toast-body">
      {{ message }}
    </div>
  </div>
  `,
  preserveWhitespaces: false
})
export class ToastComponent extends Toast {

  // constructor is only necessary when not using AoT
  constructor(
    toastrService: ToastrService,
    toastPackage: ToastPackage
  ) {
    super(toastrService, toastPackage);
  }

  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    return false;
  }
}
