import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../../core';
import { UserService } from 'src/app/core/user/user.service';
import { FundService } from 'src/app/shared/services/fund/fund.service';

@Injectable()
export class AuthorizationsGuard implements CanActivate {

  constructor(
    private userService: UserService,
    private loaderService: LoaderService,
    private fundService: FundService
  ) { }
  canActivate(): Observable<boolean> {
    this.loaderService.show();
    return this.userService.checkServiceAccess()
      .pipe(mergeMap(hasServiceAccess => {
        this.loaderService.hide();
        if (hasServiceAccess) {
         return this.fundService.getFunds()
          .pipe(
              mergeMap(funds => {
                  const userHasFunds = funds != null && funds.value != null && funds.value.length > 0;
                  if (!userHasFunds) {
                    return this.redirectToUnauthorizedSgMarketsUrl();
                  }
                  return from([userHasFunds]);
                  })
              );
        } 
        else 
        {
          return this.redirectToUnauthorizedSgMarketsUrl();
        }
  }))
}

  private redirectToUnauthorizedSgMarketsUrl(): Observable<boolean> {
    if (environment.unauthorizedSgMarketsUrl) {
      window.location.href = environment.unauthorizedSgMarketsUrl;
    }
    return from([false]);
  }
}
