import { ConfigurationService } from 'src/app/core';
import { HttpClient } from '@angular/common/http';
import { IFunds, IFundModel, FundSearchMode, ICurrencies } from '../../models/fund.model';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AlertCategory } from '../../models';

@Injectable()
export class FundService {
  constructor(private httpClient: HttpClient, private configurationService: ConfigurationService) {
  }

  public getFunds(): Observable<IFunds> {
    return this.httpClient.get<IFunds>(this.configurationService.getFundsUrl());
  }
  public searchFunds(searchmode: FundSearchMode, currency: string | null, alertCategory: AlertCategory): Observable<IFunds> {
    const params: any = {};
    if (searchmode) params['searchmode'] = searchmode;
    if (currency) params['currency'] = currency;
    if (alertCategory) params['alertCategory'] = alertCategory;
    return this.httpClient.get<IFunds>(this.configurationService.getFundsUrl(), { params: params });
  }
  public getFund(subFundId: string, isin: string): Observable<IFundModel> {
    const params: any = {};
    if (subFundId) { params['subFundId'] = subFundId; }
    if (isin) { params['isin'] = isin; }
    return this.httpClient.get<IFundModel>(this.configurationService.getFundUrl(), { params });
  }
  public getCurrencies(): Observable<ICurrencies> {
    return this.httpClient.get<ICurrencies>(this.configurationService.getCurrenciesUrl());
  }
}
