
export const environment = {
  production: JSON.parse('{%production%}'),
  version: `v${require('../../package.json').version}-{%version:environement%}`,
  unauthorizedSgMarketsUrl: '{%unauthorizedSgMarketsUrl%}',
  frontApiBaseUrl: '{%frontApiBaseUrl%}',
  piwikBase: '{%piwikBase%}',
  piwikId: '{%piwikId%}'
};

