import { Injectable, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import {Location} from '@angular/common';
import { Subscription } from 'rxjs';
import { PIWIK_CONST } from './piwik-tracking.constants';
import { environment } from 'src/environments/environment';
import { filter } from 'rxjs/operators';
import { ISgwtConnectElement } from 'src/main';




@Injectable()
export class PiwikTrackingService implements OnDestroy {
    private routerEventsSubscription: Subscription;

    constructor(private router: Router,
                private title: Title,
                private location: Location) {
    }

    public configurePiwikTracker() {
        const wrapper = document.querySelector('sgwt-connect') as ISgwtConnectElement;

        const paq: string[][] = window._paq || [];
        paq.push(['requireCookieConsent']);
        paq.push(['setTrackerUrl', environment.piwikBase + PIWIK_CONST.config.trakcerUrl]);
        paq.push(['setSiteId', environment.piwikId]);
        window._paq = paq;

        this.addPiwikScriptReference();

        if (wrapper) {
            const  sgwtConnect =  wrapper.sgwtConnect;
            if (sgwtConnect) {
                window._paq.push(['setUserId', sgwtConnect.getIdTokenClaims()!.sub]);
            }
        }
    }

    public enableRouteNavigationTracking() {
        // Track the Angular Route change
        this.routerEventsSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => {
                window._paq.push(['setDocumentTitle', this.title.getTitle()]);
                window._paq.push(['setCustomUrl', this.location.path()]);
                window._paq.push([PIWIK_CONST.methods.trackPageView]);
            });
    }

    private addPiwikScriptReference() {
        const pa = document.createElement('script');
        pa.type = 'text/javascript';
        pa.async = true;
        pa.src = environment.piwikBase + PIWIK_CONST.config.scriptFileName;
        const s = document.getElementsByTagName('script')[0];
        s.parentNode!.insertBefore(pa, s);
    }

    public ngOnDestroy(): void {
        if (this.routerEventsSubscription) {
            this.routerEventsSubscription.unsubscribe();
        }
    }
}
