import * as moment from 'moment';

export interface INotificationAlertModel {
  alertId: number;
  alertGroupId: number;
  owner: string;
  notificationIcIds: string[];
  alertCategory: string;
  isin: string;
  subFundId: string;
  umbrellaId : string;
  subFundName: string;
  fundName: string;
  umbrellaName : string;
  fundCurrency: string;
  subFundCurrency: string;
  umbrellaCurrency : string;
  alertLabel: string;
  definedThresholdSense: number;
  definedThreshold: number;
  definedAmountSense: number;
  definedAmount: number;
  creationDate: Date;
  modificationDate: Date;
  managementCompanyCode: string;
  managementCompanyRCTCode: string;
}

export interface INotificationModel {
  alertId: number;
  alert?: INotificationAlertModel;
  notificationId: number;
  generationDate: Date;
  cutOffDate: Date;
  aum: number;
  newEstimatedAum?: number;
  netFlowAmount: number;
  netFlowSense: number;
  estimatedAmount?: number;
  estimatedQuantity?: number;
  currency: string;
  estimatedPercentage?: number;
  clientLabel: string;
  tradeReference: string;
  cancellationDate: Date;
  cancellationNotificationId: number;
}

export class NotificationModel implements INotificationModel {
  alertId: number;
  alert?: INotificationAlertModel;
  notificationId: number;
  generationDate: Date;
  cutOffDate: Date;
  aum: number;
  newEstimatedAum?: number;
  netFlowAmount: number;
  netFlowSense: number;
  estimatedAmount?: number;
  estimatedQuantity?: number;
  currency: string;
  estimatedPercentage?: number;
  clientLabel: string;
  tradeReference: string;
  cancellationDate: Date;
  cancellationNotificationId: number;
  cancellationString: string;

  constructor(notification: INotificationModel) {
    this.alertId = notification.alertId;
    this.alert = notification.alert;
    this.notificationId = notification.notificationId;
    this.generationDate = notification.generationDate;
    this.cutOffDate = notification.cutOffDate;
    this.aum = notification.aum;
    this.newEstimatedAum = notification.newEstimatedAum;
    this.netFlowAmount = notification.netFlowAmount;
    this.netFlowSense = notification.netFlowSense;
    this.estimatedAmount = notification.estimatedAmount;
    this.estimatedQuantity = notification.estimatedQuantity;
    this.estimatedPercentage = notification.estimatedPercentage;
    this.clientLabel = notification.clientLabel;
    this.tradeReference = notification.tradeReference;
    this.cancellationDate = notification.cancellationDate;
    this.cancellationNotificationId = notification.cancellationNotificationId;
  }

  get localGenerationDate(): Date {
    return moment.utc(this.generationDate).local().toDate();
  }

  get localCancellationDate(): Date {
    return moment.utc(this.cancellationDate).local().toDate();
  }
}
