import { NgModule, Optional, SkipSelf, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfigurationService } from './configuration-service/configuration.service';
import { TranslateModule } from '@ngx-translate/core';
import { ToastComponent } from './toast/toast.component';
import { ToastrModule } from 'ngx-toastr';
import { NavbarComponent } from './navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { LoaderComponent } from './loader/loader-component/loader.component';
import { LoaderService } from './loader/loader-service/loader.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalHttpInterceptorService } from './http-interceptor/global-http-interceptor.service';
import { NavigationService } from './navigation/navigation.service';
import { PiwikTrackingService } from './analytics/piwik-tracking.service';
import { UserService } from './user/user.service';

@NgModule({
  declarations: [
    NavbarComponent,
    ToastComponent,
    LoaderComponent
  ],
  imports: [
    BrowserAnimationsModule,
    TranslateModule.forChild(),
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      timeOut: 5000,
      closeButton: true,
      toastComponent: ToastComponent,
      toastClass: 'toast',
      iconClasses: {
        error: 'toast-danger',
        info: 'toast-info',
        success: 'toast-success',
        warning: 'toast-danger'
      }
    }),
    RouterModule
  ],
  providers: [
    ConfigurationService,
    LoaderService,
    NavigationService,
    PiwikTrackingService,
    UserService,
    { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true }
  ],
  exports: [
    NavbarComponent,
    LoaderComponent
  ],
  entryComponents: [ToastComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
