import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from 'src/app/core';
import { IcUsersModel, toIcUsersModel, IcUsersApiModel } from '../../models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ManagementCompanyService {
  constructor(private httpClient: HttpClient, private configurationService: ConfigurationService) {
  }

  public getIcUsers(): Observable<IcUsersModel> {
    return this.httpClient.get<IcUsersApiModel>(this.configurationService.getManagementCompanyIcUsersUrl()).pipe(map(response => toIcUsersModel(response)));
  }
}
