<nav class="py-3 px-2 navbar-expand-lg navbar-light bg-white">

  <div class="d-flex align-items-center justify-content-between w-100 px-3">
    <a routerLink="/"  class="navbar-brand">
      <img alt="Logo Société Générale" src="./assets/logo.svg" height="32" width="32">
    </a>

    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" routerLink="/notifications" routerLinkActive="active" translate>navbar.notifications
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/alerts" routerLinkActive="active" translate>navbar.alertSettings
          </a>
        </li>
      </ul>
    </div>

    <div class="d-flex justify-content-end align-items-center">
      <sgwt-account-center
        #sgwtAccountCenter
        available-languages="en,fr"
        language="en"
        authentication="sg-connect-v2"
        mode="sg-markets"
      >
      </sgwt-account-center>

      <svg class="d-none d-md-block overflow-visible ml-3" width="135" height="32">
        <image xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="assets/sg_market.svg" x="0" y="0" height="16" width="132"></image>
        <!--Change here name of services-->
        <text class="font-family-display" x="0" y="32" height="16">Fund Alerts</text>
      </svg>
    </div>

  </div>
</nav>
