import { Component, OnInit, Input, ElementRef } from '@angular/core';

declare var $: any;

@Component({
  selector: 'liabilities-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.scss']
})
export class NotAuthorizedComponent implements OnInit {
  @Input()
  title = 'notAuthorized.userHasNotAccess.title';

  @Input()
  message = 'notAuthorized.userHasNotAccess.content';

  @Input()
  displayContactButton = true;

  constructor(private rootNode: ElementRef) { }

  ngOnInit(): void {
  }

  contactUs(): void {
    this.onHelpCenterButtonClick();
    setTimeout(() => this.onHelpCenterButtonClick(), 200);
  }

  private onHelpCenterButtonClick() {
    $(this.rootNode.nativeElement.parentElement.parentElement).find('#sgwt-help-center button').click();
  }
}
