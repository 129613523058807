import { INotificationModel, NotificationModel } from './notification.model';

export interface INotificationsModel {
  value: INotificationModel[];
}

export class NotificationsModel {
  value: NotificationModel[];

  constructor(data: INotificationsModel) {
    this.value = data.value.map(notification => new NotificationModel(notification));
  }

}
