<liabilities-navbar></liabilities-navbar>

<liabilities-loader></liabilities-loader>

<div class="full-screen">
  <router-outlet></router-outlet>
</div>

<sgwt-help-center
  id="sgwt-help-center"
  application-id="fund-alerts"
  sg-connect-support="sg-connect-v2"
  default-send-to="c2VydmljZS5yZWxhdGlvbnNpbnZlc3Rpc3NldXJzQHNnc3Muc29jZ2VuLmNvbQ=="
></sgwt-help-center>

<sgwt-splash-screen id="splash-screen" application="fund-alerts"></sgwt-splash-screen>

<sgwt-mini-footer #footer container="container-fluid" design="light" mode="sg-markets" contact-us-by-help-center="#sgwt-help-center" accessibility-compliance="none"></sgwt-mini-footer>
