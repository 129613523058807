import { Component, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/core/navigation/navigation.service';
import { Location } from '@angular/common';

declare var $: any;
@Component({
  selector: 'liabilities-api-connection-error',
  templateUrl: './api-connection-error.component.html',
  styleUrls: ['./api-connection-error.component.css']
})
export class ApiConnectionErrorComponent implements OnInit {
  constructor(private router: Router,
              private rootNode: ElementRef,
              private location: Location,
              private navigationService: NavigationService) { }

  ngOnInit(): void {
  }

  goBack(): void {
    const previousUrl = this.navigationService.getPreviousUrl();
    if (previousUrl === '/notifications' || previousUrl === '/alerts') {
      this.location.back();
    } else {
      this.router.navigate(['/notifications']);
    }
  }

  contactUs(): void {
    this.onHelpCenterButtonClick();
    setTimeout(() => this.onHelpCenterButtonClick(), 200);
  }

  private onHelpCenterButtonClick() {
    $(this.rootNode.nativeElement.parentElement.parentElement).find('#sgwt-help-center button').click();
  }
}
