import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ApiConnectionErrorComponent } from './components/api-connection-error/api-connection-error.component';
import { ErrorRoutingModule } from './error-routing.module';


@NgModule({
  declarations: [
    ApiConnectionErrorComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ErrorRoutingModule
  ],
  exports: [
    ApiConnectionErrorComponent
  ]
})

export class ErrorHandlingModule { }
