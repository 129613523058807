import { HttpClient, HttpClientModule, HttpRequest } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';

import { SGWTConnectModule } from '@sgwt/connect-angular';
import { ISgwtConnectElement } from '../main';

import { AppRoutingModule } from './app-routing.module';

import { AlertService } from './shared/services/alert/alert.service';
import { NotificationService } from './shared/services/notification/notification.service';
import { FundService } from './shared/services/fund/fund.service';
import { ManagementCompanyService } from './shared/services/management-company/management-company.service';

import { CommonService } from './shared/services/common/common.service';
import { AmountFormatService } from './shared/services/common/amount-format.service';
import { AuthorizationModule } from './authorization/authorization.module';
import { ErrorHandlingModule } from './error-handling/error-handling.module';
import { CoreModule } from './core/core.module';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// Export sgwtConnect factory (AOT requirement):
export function sgwtConnectFactory() {
  const wrapper = document.querySelector('sgwt-connect') as ISgwtConnectElement;
  return wrapper.sgwtConnect;
}

// Export sgwtConnect predicate (AOT requirement):
export function sgwtConnectPredicate({ url }: HttpRequest<any>) {
  // Only add the Authorization header with your private access_token
  // to requests where url starts with "https://myapi"
  return true;
}
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SGWTConnectModule.forRoot(sgwtConnectFactory, sgwtConnectPredicate),
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CoreModule,
    AuthorizationModule,
    ErrorHandlingModule
  ],
  providers: [
    FundService,
    AlertService,
    ManagementCompanyService,
    NotificationService,
    CommonService,
    AmountFormatService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
