import { Injectable } from '@angular/core';
import { NotificationModel } from '../../models';
import { FormGroup, ValidationErrors, FormControl } from '@angular/forms';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  isCutoffDateToday(notification: NotificationModel) {
    if (!notification) {
      return false;
    }

    const cutoffDate = new Date(notification.cutOffDate);
    return this.isToday(cutoffDate);
  }

  private isToday(date: Date) {
    const today = moment();
    return moment(date).isSame(today, 'day');
  }

  triggerElement(list: any[], element: any): any[] {
    const index = list.indexOf(element);
    if (index === -1) {
      list.push(element);
    } else {
      list.splice(index, 1);
    }
    return list;
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode === 46 && event.target.value.includes('.')) {
      return false;
    }
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  isFieldProvided = (fieldName: string, fg: FormGroup) => {
    const field = fg.get(fieldName)!.value;
    return !!field;
  }

  atLeastOne(...fields: string[]) {
    return (fg: FormGroup): ValidationErrors | null => {
      return fields.some(fieldName => this.isFieldProvided(fieldName, fg))
        ? null
        : ({ atLeastOne: 'At least one field has to be provided.' } as ValidationErrors);
    };
  }

    hasFunds =function( ) {
      return (fg: FormGroup): ValidationErrors | null => {
        return !fg.get('funds')!.value||fg.get('funds')!.value.some((q: any)=> true)
          ? null
          : ({ atLeastOne: 'At least one fund has to be provided.' } as ValidationErrors);
      };
  }
}
