import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from 'src/app/core';
import { INotificationModel, NotificationModel, INotificationsModel, NotificationsModel } from '../../models';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class NotificationService {
  constructor(private httpClient: HttpClient, private configurationService: ConfigurationService) {
  }

  public getAllManagementCompanyNotifications(): Observable<NotificationsModel> {
    const url = this.configurationService.getAllManagementCompanyNotificationsUrl();
    return this.httpClient.get<INotificationsModel>(url).pipe(map(response => new NotificationsModel(response)));
  }

  public getNotification(notificationId: number): Observable<NotificationModel> {
    const url = this.configurationService.getNotificationUrl(notificationId);
    return this.httpClient.get<INotificationModel>(url).pipe(map(response => new NotificationModel(response)));
  }
}
